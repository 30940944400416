/** @format */

function FailedPage() {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <p className="text-sm text-gray-600">
                Checkout session was invalid. Please go{" "}
                <a
                    href="https://dashboard.simplesaucegroup.com/login"
                    className="text-accent"
                >
                    Home
                </a>
            </p>
        </div>
    );
}

export default FailedPage;
