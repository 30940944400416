/** @format */

import { useEffect, useState } from "react";
import SetupReferralBanner from "../../../components/banners";
import NavBar from "../../../components/navBar";
import { Referral, ReferralCards } from "../../../models/referral";
import StatusTags, { PaidStatusTags } from "../../../components/statusTags";
import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import {
    fetchOnboardingUrl,
    fetchReferrals,
    transferFunds,
} from "./api/referralsApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import LoadingWheel from "../../../components/loadingWheel";
import { formatMoney } from "../../../utils/format/formatMoney";

function ReferralsPage() {
    const { showNotification } = useNotification();

    const [referralsCards, setReferralsCards] = useState<ReferralCards | null>(
        null
    );
    const [referrals, setReferrals] = useState<Referral[]>([]);
    const [setupNeeded, setSetupNeeded] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchReferrals();
            if (data.status === "success") {
                setReferrals(data.data.referrals);
                setReferralsCards(data.data.cards);
                setSetupNeeded(data.data.setup_needed);
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    const [copied, setCopied] = useState(false);

    const copyUrl = () => {
        navigator.clipboard.writeText(
            "https://dashboard.simplesaucegroup.com/r/" +
                referralsCards?.referral_code
        );
        setCopied(true);

        const timeoutId = setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const copyWebsiteUrl = () => {
        navigator.clipboard.writeText(
            "https://simplesaucegroup.com?referral=" +
                referralsCards?.referral_code
        );
        setCopied(true);

        const timeoutId = setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const [transferLoading, setTransferLoading] = useState(false);
    const handleTransfer = async () => {
        setTransferLoading(true);
        const data = await transferFunds();
        if (data.status === "success") {
            showNotification(
                "Transferred funds to account.",
                "Funds have started being processed.",
                "success"
            );
        } else {
            showNotification(
                "An error occurred transfering funds.",
                data.message,
                "error"
            );
        }
        setTransferLoading(false);
    };

    const stats = [
        { name: "Total Paid Referrals", stat: referralsCards?.total_count },
        {
            name: "Total Free Referrals",
            stat: referralsCards?.free_referrals_count,
        },
        {
            name: "Total Revenue",
            stat: formatMoney(referralsCards?.total_commission),
        },
        { name: "Active Referrals", stat: referralsCards?.active_referrals },
    ];

    return (
        <div>
            <NavBar />
            {!loading ? (
                <div className="flex flex-col mx-4 sm:mx-16 md:mx-32 lg:mx-44 xl:mx-64 pt-6">
                    {setupNeeded && <SetupReferralBanner />}
                    <div>
                        <div className="flex flex-col sm:flex-row sm:items-center justify-between pt-5">
                            <h3 className="font-semibold text-2xl text-left items-start">
                                Referrals overview
                            </h3>
                            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 sm:space-y-0 space-y-2 sm:pt-0 pt-2">
                                <button
                                    onClick={() => handleTransfer()}
                                    className="flex flex-row items-center gap-2 hover:border-gray-300 font-medium text-left px-3 py-1 text-sm rounded-md bg-lightGray border border-grey-200"
                                >
                                    {transferLoading && (
                                        <span className="pr-2">
                                            <LoadingWheel
                                                color="black"
                                                size="small"
                                            />
                                        </span>
                                    )}
                                    Withdraw funds
                                </button>
                                <button
                                    onClick={() => copyWebsiteUrl()}
                                    className="flex flex-row items-center gap-2 hover:border-gray-300 font-medium text-left px-3 py-1 text-sm rounded-md bg-lightGray border border-grey-200"
                                >
                                    {copied ? (
                                        <CheckIcon className="w-4 h-4 text-green-500" />
                                    ) : (
                                        <ClipboardDocumentIcon className="w-4 h-4" />
                                    )}
                                    Copy website referral link
                                </button>
                                <button
                                    onClick={() => copyUrl()}
                                    className="flex flex-row items-center gap-2 hover:border-gray-300 font-medium text-left px-3 py-1 text-sm rounded-md bg-lightGray border border-grey-200"
                                >
                                    {copied ? (
                                        <CheckIcon className="w-4 h-4 text-green-500" />
                                    ) : (
                                        <ClipboardDocumentIcon className="w-4 h-4" />
                                    )}
                                    Copy referral link
                                </button>
                            </div>
                        </div>
                        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                            {stats.map((item) => (
                                <div
                                    key={item.name}
                                    className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                                >
                                    <dt className="truncate text-sm font-medium text-gray-500">
                                        {item.name}
                                    </dt>
                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                        {item.stat}
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                    <div className="overflow-x-scroll pt-10">
                        {referrals.length > 0 ? (
                            <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                                <thead>
                                    <tr className="text-left text-sm">
                                        <th className="py-2 min-w-32">
                                            Status
                                        </th>
                                        <th className="py-2 min-w-32">
                                            Amount
                                        </th>
                                        <th className="py-2 min-w-56">
                                            Username
                                        </th>
                                        <th className="py-2 min-w-56">Type</th>
                                        <th className="py-2 min-w-56">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {referrals.map((referral) => (
                                        <tr className="relative border-t border-gray-200 text-sm text-gray-500">
                                            <td className="py-2.5">
                                                <PaidStatusTags
                                                    paid={
                                                        referral.commission_paid
                                                    }
                                                />
                                            </td>
                                            <td className="py-2.5">
                                                {formatMoney(
                                                    referral.commission_amount
                                                )}
                                            </td>
                                            <td className="">
                                                {referral.referred_username}
                                            </td>
                                            <td className="">
                                                Membership referral
                                            </td>
                                            <td className="">
                                                {referral.created_at}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="flex flex-col items-center justify-center pt-32 text-gray-600">
                                No past referrals.
                            </div>
                        )}
                    </div>
                    {/* {results ? (
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                    {results.showing_from}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                    {results.showing_to}
                                </span>{" "}
                                of{" "}
                                <span className="font-medium">
                                    {results.total_results}
                                </span>{" "}
                                results
                            </p>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <a
                                href={`/admin/clients?offset=${calculateOffset(
                                    searchParams,
                                    "back"
                                )}`}
                                className={`${
                                    getOffset(searchParams) === 0
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Previous
                            </a>
                            <a
                                href={`/admin/clients?offset=${calculateOffset(
                                    searchParams,
                                    "forward"
                                )}`}
                                className={`${
                                    results.showing_to >= results.total_results
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Next
                            </a>
                        </div>
                    </nav>
                ) : null} */}
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center pt-44 text-gray-600">
                    <LoadingWheel />
                </div>
            )}
        </div>
    );
}

export default ReferralsPage;
