/** @format */

import { useEffect, useState } from "react";
import { verifyValidWebhook } from "../api/authenticationApi";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { checkAuth } from "../../../utils/auth/verifyAuthToken";
import { useNotification } from "../../../utils/notification/notificationContext";
import { verify } from "crypto";
import LoadingWheel from "../../../components/loadingWheel";

function SuccessPage() {
    const { showNotification } = useNotification();
    const navigate = useNavigate();
    const location = useLocation();
    const [isUpdated, setIsUpdated] = useState(false);

    const handleStripeRedirection = async (session_id: string) => {
        const data = await verifyValidWebhook(session_id);
        if (data.status === "success") {
            navigate(`/login?jwt_token=${data.data.jwt_token}`);
        } else if (data.message === "webhook-not-found") {
        } else {
            navigate("/failed");
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const session_id = queryParams.get("session_id");

        if (!session_id) {
            navigate("/failed");
            return;
        }

        let counter = 0;

        const intervalId = setInterval(() => {
            if (counter >= 10) {
                clearInterval(intervalId);
                navigate("/login");
                return;
            }

            handleStripeRedirection(session_id);
            counter++;
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="flex flex-col items-center space-y-2">
                <LoadingWheel />
                <p className="text-sm text-gray-600">
                    You are being redirected to the dashboard.
                </p>
                <p className="text-sm text-gray-600">
                    If you aren't logged in after a minute, check your email for
                    your license key.
                </p>
            </div>
        </div>
    );
}

export default SuccessPage;
