import { NavigateFunction } from "react-router-dom";
import Cookies from "js-cookie";

export const verifyLicenseKey = async (license_key: string) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/auth/license",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({license_key})
            }
        )

        const data = await response.json();
        return data
    } catch(error) {
        return {data : {status: "success", message: String(error)}}
    }
}

export const adminLoginRequest = async (
    email: string,
    password: string
) => {
    try {
        const jsonBody = {
            "email": email,
            "password": password
        }
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/admin/login",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(jsonBody)
            }
        )

        const data = await response.json();

        return data
    } catch(error) {
        return { data: {status: "failed", message: String(error)}}
    }
}

export const adminSignup = async (
    email: string,
    password: string
) => {
    try {
        const jsonBody = {
            "email": email,
            "password": password
        }
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/admin/signup",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(jsonBody)
            }
        )

        const data = await response.json();

        return data
    } catch(error) {
        return { data: {status: "failed", message: String(error)}}
    }
}

export const verifyValidWebhook = async (
    session_id: string,
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/api/v1/check-webhook-status?session_id=${session_id}`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json"
                },
            }
        )

        const data = await response.json();

        return data
    } catch(error) {
        return { data: {status: "failed", message: String(error)}}
    }
}